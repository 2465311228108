<script setup>
import InputError from "@/Components/InputError.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <Head :title="__('Log in')" />

    <section class="p-0 h-100">
        <div class="container grid place-items-center w-100 p-0" style="height: calc(100vh - 120px);">
            <div class="row justify-content-center align-items-center mx-auto w-100 max-w-xl">
                <div class="col-12">
                    <div class="bg-mode shadow rounded-3 overflow-hidden">
                        <div class="row g-0">
                            <!-- Vector Image -->
                            <!--                            <div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">-->
                            <!--                                <div class="p-3 p-lg-5">-->
                            <!--                                    <img src="assets/images/element/signin.svg" alt="">-->
                            <!--                                </div>-->
                            <!--                                &lt;!&ndash; Divider &ndash;&gt;-->
                            <!--                                <div class="vr opacity-1 d-none d-lg-block"></div>-->
                            <!--                            </div>-->

                            <!-- Information -->
                            <div class="col-lg-12 order-1">
                                <div class="p-4 p-sm-7">
                                    <div v-if="status" class="mb-4 alert alert-success">
                                        {{ status }}
                                    </div>

                                    <!-- Title -->
                                    <h1 class="mb-2 h3">Login</h1>
                                    <p class="mb-0">
                                        {{ __('New here?') }}
                                        <Link :href="route('register')" class="ml-2">
                                            {{ __('create account') }}
                                        </Link>
                                    </p>
                                    <!-- Form START -->
                                    <form class="mt-4 text-start" @submit.prevent="submit">
                                        <!-- Email -->
                                        <div class="mb-3">
                                            <label class="form-label">{{ __('Email') }}</label>
                                            <input type="email" class="form-control" v-model="form.email" required autofocus
                                                autocomplete="username" />
                                        </div>
                                        <InputError class="mt-2" :message="form.errors.email" />

                                        <!-- Password -->
                                        <div class="mb-3 position-relative">
                                            <label class="form-label">{{ __('Password') }}</label>
                                            <input class="form-control" type="password" id="psw-input"
                                                v-model="form.password" required autocomplete="current-password" />
                                        </div>
                                        <InputError class="mt-2" :message="form.errors.password" />

                                        <!-- Remember me -->
                                        <div class="mb-3 d-sm-flex justify-content-between">
                                            <div>
                                                <input type="checkbox" class="form-check-input mr-2" id="rememberCheck"
                                                    name="remember" v-model="form.remember" />
                                                <label class="form-check-label" for="rememberCheck">
                                                    {{ __('Remember me') }}
                                                </label>
                                            </div>
                                            <Link :href="route('password.request')">
                                                {{ __('Forgot your password?') }}
                                            </Link>
                                        </div>
                                        <!-- Button -->
                                        <div>
                                            <button type="submit" class="btn btn-primary w-100 mb-0" :class="{
                                                'opacity-25':
                                                    form.processing,
                                            }" :disabled="form.processing">
                                                {{ __('Login') }}
                                            </button>
                                        </div>

                                        <!-- Divider -->
                                        <div class="position-relative my-4">
                                            <hr />
                                            <p
                                                class="small bg-mode position-absolute top-50 start-50 translate-middle px-2">
                                                {{ __('or') }}
                                            </p>
                                        </div>

                                        <!-- Google and facebook button -->
                                        <div class="vstack gap-3">
                                            <a href="#" class="btn btn-light mb-0">
                                                <i class="fab fa-fw fa-google text-google-icon me-2"></i>
                                                {{ __('Login with Google') }}
                                                </a>
                                            <a :href="route('auth.facebook')" class="btn btn-light mb-0">
                                                <i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>
                                                {{ __('Login with Facebook') }}
                                            </a>
                                        </div>
                                    </form>
                                    <!-- Form END -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
